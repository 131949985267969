<template>
  <div style="min-width: 960px">
    <BandHeader active-index="/product"></BandHeader>
    <div style="background: #f5f5f5;padding-bottom: 50px">
      <div style="margin: 60px 5% 0 5%">
        <img src="../../assets/Band/9.jpg"
             style="width: 90vw;height: 33.75vw;margin-top: 50px;min-width: 864px;min-height: 324px;"/>
      </div>

      <p style="text-align: left;margin:50px 0 50px 5%;">星火系列</p>
      <div style="margin:0 5%;">
        <router-link to="/product1">
          <el-row :gutter="80" v-for="(spark, index) of sparks" :key="index" style="margin-top: 30px">
            <el-col :span="6" v-for="item of spark" :key="item.id">
                <div class="shadow" style="border: 1px solid #e5e5e5;border-radius: 4px;transition: .3s;">
                  <el-image :src="item.src" lazy style="display: block;width: 100%"/>
                  <div style="background-color: #fff;">
                    <p style="">{{ item.title }}</p>
                    <p style="padding: 8px;color: #999999;">{{ item.desc }}</p>
                    <p style="padding: 8px;color: #ffa800">{{ item.price }}</p>
                  </div>
                </div>
            </el-col>
          </el-row>
        </router-link>
      </div>

      <p style="text-align: left;margin:50px 0 50px 5%;">其他设备</p>

      <div style="margin:0 5%;">
        <router-link to="/product2">
          <el-row :gutter="80" v-for="(other, index) of others" :key="index" style="margin-top: 30px">
            <el-col :span="6" v-for="item of other" :key="item.id">
              <div class="shadow" style="border: 1px solid #e5e5e5;border-radius: 4px;transition: .3s;">
                <el-image :src="item.src" lazy style="display: block;width: 100%"/>
                <div style="background-color: #fff;">
                  <p style="">{{ item.title }}</p>
                  <p style="padding: 8px;color: #999999;">{{ item.desc }}</p>
                  <p style="padding: 8px;color: #ffa800">{{ item.price }}</p>
                </div>
              </div>
            </el-col>
          </el-row>
        </router-link>
      </div>

    </div>
    <Footer/>
  </div>
</template>

<script>
import BandHeader from "@/components/BandHeader";
import Footer from "@/components/Footer";

export default {
  name: "product",
  data() {
    return {
      allProjects: [
        {
          id: '0',
          title: '星火',
          desc: '20天超长续航/大屏幕/跑步专用',
          price:'￥599',
          src: require('../../assets/Band/分类小图800-800.jpg'),
        },
      ],
      allProjects2: [
        {
          id: '0',
          title: '运动小秘',
          desc: '60天超长续航/跑姿分析/跑步专用',
          price:'￥299',
          src: require('../../assets/Band/xiaomi.jpg'),
        },
      ],
    }
  },

  components: {
    BandHeader, Footer
  },

  props: {
    activeIndex: {
      type: String
    }
  },
  computed: {
    sparks() {
      const sparks = []
      this.allProjects.forEach((item, index) => {
        const spark = Math.floor(index / 4)//3代表3条为一行，随意更改
        if (!sparks[spark]) {
          sparks[spark] = []
        }
        sparks[spark].push(item)
      })
      return sparks
    },
    others(){
      const others = []
      this.allProjects2.forEach((item, index) => {
        const other = Math.floor(index / 4)//3代表3条为一行，随意更改
        if (!others[other]) {
          others[other] = []
        }
        others[other].push(item)
      })
      return others
    }
  },
  methods: {},
}
</script>

<style scoped>
@media screen and (max-width: 576px) {
  .product{
    font-size: 10px;
  }
}

@media screen and (max-width: 1200px) {
  .product{
    font-size: 12px;
  }
}

@media screen and (min-width: 1200px) {
  product{
    font-size: 14px;
  }
}

.shadow:hover{
  box-shadow: 0 0 8px 3px #d7d7d7;
}

a, a:hover, a:active, a:visited, a:link, a:focus {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  outline: none;
  background: none;
  text-decoration: none;
  color: #3e3e3e;
}
</style>